<template>
  <div class="container py-5 min-vh-50">
    <div v-if="categories" class="merch-categories-container mb-3">
      <a
        class="merch-categories-item"
        :class="selectedCategory(0)"
        @click="selectCategory(0)"
        @keydown="selectCategory(0)"
      >
        Tutto 😎
      </a>
      <a
        v-for="c in categories"
        :key="c.id"
        class="merch-categories-item"
        :class="selectedCategory(c.id)"
        @click="selectCategory(c.id)"
        @keydown="selectCategory(c.id)"
      >
        {{ categoryName(c) }}
      </a>
    </div>
    <div class="merch-alert-container">
      <span class="icon">😌</span>
      <span>Spedizione gratuita a partire da <u>35€</u></span>
      <span class="icon">😌</span>
    </div>
    <div v-if="false" class="merch-warning-alert-container">
      Per gli ordini effettuati dopo il 18 Dicembre non è garantita la consegna entro Natale
    </div>
    <div v-if="products.length > 0" class="merch-products-container">
      <router-link
        :to="{ name: 'MerchDetail', params: { id: p.code } }"
        v-for="p in products"
        :key="p.code"
        class="col-12 col-md-6 col-lg-4 merch-products-item"
      >
        <MerchItem :merch="p"></MerchItem>
      </router-link>
    </div>
    <div v-else>
      {{ $t('common.emptysearch') }}
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/core/api';
import MerchItem from '@/components/MerchItem.vue';

export default {
  components: {
    MerchItem,
  },
  setup() {
    const {
      get,
      post,
    } = api();
    const categories = ref([]);
    const products = ref([]);
    const idCategory = ref(0);
    const route = useRoute();

    function categoryName(category) {
      return category?.locs[0]?.name ?? '';
    }

    function selectCategory(id) {
      idCategory.value = id;
    }

    function selectedCategory(id) {
      return idCategory.value === id ? 'selected' : '';
    }

    onMounted(() => {
      // Set initial category from route parameter or default to 0
      const initialCategoryId = parseInt(route.params.categoryId, 10) || 0;
      idCategory.value = initialCategoryId;

      get('getProductCategories')
        .then((res) => {
          categories.value = res?.data ?? [];
          const data = {
            id_category: idCategory.value,
          };
          post('getProducts', data)
            .then((res2) => {
              products.value = res2?.data ?? [];
            });
        });
    });

    watch(() => idCategory.value, () => {
      const data = {
        id_category: idCategory.value,
      };
      post('getProducts', data)
        .then((res2) => {
          products.value = res2?.data ?? [];
        });
    });

    return {
      products,
      categories,
      categoryName,
      idCategory,
      selectCategory,
      selectedCategory,
    };
  },
};
</script>

<style lang="scss">
.merch-categories-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;

  .merch-categories-item {
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    color: black;
  }

  .merch-categories-item:hover {
    color: #b82620;
  }

  .merch-categories-item.selected {
    color: #b82620;
  }
}

.merch-alert-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  color: #b82620;
  gap: 15px;

  .icon {
    font-size: 22px;
  }
}

.merch-warning-alert-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-size: 18px;
  color: #6e6e6e;
}

.merch-products-container {
  display: flex;
  flex-wrap: wrap;
  align-items: start;

  .merch-products-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    text-decoration: none;

    .merch-products-item-image {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;

      img {
        max-width: 100%;
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }

    .merch-products-item-info {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      flex-wrap: nowrap;

      .merch-products-item-name {
        font-family: DM Sans;
        font-size: 15px;
        font-weight: bold;
        color: #b82620;
      }

      .merch-products-item-price {
        font-family: DM Sans;
        font-size: 16px;
        font-weight: bold;
        color: black;
        min-width: 100px;
        text-align: right;
      }

      .merch-products-item-price.discounted {
        color: #bfbfbf;
        text-decoration: line-through;
      }

      .merch-products-item-discounted-price {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
