import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import api from '@/core/api';
import { piniaCart, piniaUser } from '@/pinia';
import ContactsView from '@/views/ContactsView.vue';
import TemplateView from '../template/TemplateView.vue';
import HomeView from '../views/HomeView.vue';
import ExperienceView from '../views/ExperienceView.vue';
import ExperienceDetailView from '../views/ExperienceDetailView.vue';
import MerchView from '../views/MerchView.vue';
import MerchDetailView from '../views/MerchDetailView.vue';
import CartView from '../views/CartView.vue';
import SuccessView from '../views/SuccessView.vue';
import UserView from '../views/UserView.vue';
import ConditionsView from '../views/ConditionsView.vue';
import UserViewReset from '../views/UserViewReset.vue';
import POSView from '../views/POSView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
    component: TemplateView,
    children: [
      { path: '/home', name: 'Home', component: HomeView },
      { path: '/experiences', name: 'Experiences', component: ExperienceView },
      { path: '/experience-detail/:id', name: 'ExperienceDetail', component: ExperienceDetailView },
      { path: '/merch/:categoryId?', name: 'Merch', component: MerchView },
      { path: '/merch-detail/:id', name: 'MerchDetail', component: MerchDetailView },
      { path: '/cart', name: 'Cart', component: CartView },
      { path: '/contacts', name: 'Contacts', component: ContactsView },
      { path: '/success/:id', name: 'Success', component: SuccessView },
      { path: '/user', name: 'User', component: UserView },
      { path: '/conditions', name: 'Conditions', component: ConditionsView },
      { path: '/point_of_sales', name: 'Point of Sales', component: POSView },
      { path: '/reset/:token', name: 'UserViewReset', component: UserViewReset },
      { path: '/:pathMatch(.*)*', name: 'Home', component: HomeView },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  const { get } = api();
  const pinia = piniaCart();
  const piniaUserObject = piniaUser();
  piniaUserObject.getJwtLocal();
  get('getCartCount')
    .then((res) => {
      const count = res?.data?.count ?? 0;
      const timer = res?.data?.expiration ?? 0;
      pinia.addCounter(count);
      pinia.addTimer(timer);
    });
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
